import { Button } from "antd"


export default function DeleteBtn() {
    return (
        <>
            <Button type="primary" className="deletebtn">
                Delete
            </Button>
        </>
    )
}