import { Button } from "antd"


export default function EditBtn() {
    return (
        <>
            <Button type="primary" className="editbtn">
                Edit
            </Button>
        </>
    )
}