export default function MiddleSecContent2() {
    return (
        <>
            <div className='ModalMiddleSecContent2'>
                <p>Hi Nigel,</p>
                <p>
                    please check regarding our refund policy and your eligibility
                    regarding it.
                </p>
                <p>https://aqualogodesign.com/terms-and-conditions/ </p>
                <p>please let me know as to how to move forward, either we can
                    proceed as per your eligibility or we can provide you an
                    alternative service for this.</p>
                <p>Thanks. </p>
                <p>Sarah</p>
            </div>
        </>
    )
}





